import React, { useEffect } from "react"

// Utils
import { graphql } from "gatsby"
import { colors } from "utils/variables/"

// Libraries
import AOS from "aos"
import "aos/dist/aos.css"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Layout
import Layout from "layouts/layout-primary"

// Components
import ContextConsumer from "components/context/"
import Seo from "components/seo/"
import { CaseStudyTopic } from "components/case-study-section/"

// Sections
import Hero from "sections/case-studies/lymemind/hero"
import TheProject from "sections/case-studies/lymemind/the-project"
import TheChallenge from "sections/case-studies/lymemind/the-challenge"
import TheSolution from "sections/case-studies/lymemind/the-solution"
import TheResults from "sections/case-studies/lymemind/the-results"
import Cta from "components/case-study-call-to-action/"
import NextProject from "components/case-study-next-project/"
import NextProjectHero from "sections/case-studies/acgen/hero"

const LymeMIND = props => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  const {
    openGraphImage: { file: publicURL },
  } = props.data

  const { t } = useTranslation()

  return (
    <Layout menuColor={colors.supernova}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("LymeMIND Case Study - Indicius Design Agency")}
        description={t(
          "We design websites, apps, and brand identities for amazing clients. Find out how we redesigned Columbia University's genomics app for on-the-go analysis."
        )}
        image={publicURL}
      />
      <Hero />
      <CaseStudyTopic>
        <TheProject />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheChallenge />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheSolution />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheResults />
      </CaseStudyTopic>
      <Cta
        title={t("Need build an attention-grabbing website for your brand?")}
      />
      <NextProject url="/work/acgen" nextProjectHero={<NextProjectHero />} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    openGraphImage: file(
      relativePath: { eq: "case-studies/lymemind/featured-image.png" }
    ) {
      publicURL
    }
  }
`

export default LymeMIND
