import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Img from "gatsby-image"

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  padding-bottom: 0 !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}

  .image-wrapper {
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    ${breakpoint.medium`
      flex-wrap: nowrap;
      align-items: flex-end;
      margin-top: 120px;
    `}

    .image {
      width: 100%;

      &.image--one {
        max-width: 595px;
        z-index: 4000;

        ${breakpoint.medium`
          order: 1;
          width: 40%;
          position: relative;
          left: -10%;
        `}
      }

      &.image--two {
        max-width: 888px;

        ${breakpoint.medium`
          order: 0;
          width: 62%;
        `}
      }
    }
  }
`

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "case-studies/lymemind/the-solution-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 595, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(
        relativePath: { eq: "case-studies/lymemind/the-solution-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 888, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              A dynamic self-administered site that makes it easy for both
              scientists and patients to know exactly what's going on in the
              LymeMIND community.
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              We kicked off the project with a Lightning Decision Jam, a
              workshops that helped us identify what worked and what didn't in
              their current site. It also allowed us to align the team's
              priorities and think of potential solutions so that the process
              fit their specific needs.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Next, we carried out a Brand Story workshop to help them
              understand who they're talking to, what problems they're dealing
              with and what we aim to achieve. Using those insights as a basis,
              we started a rebranding process and also began to generate brand
              copy guidelines. We knew that a content-first approach to the
              design meant the final product would be much more cohesive.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Lastly, we consolidated the final sitemap and moved full speed
              ahead with the website design. We made sure that the designs being
              produced were well thought out in terms of content management and
              functionality. For the Development process, we knew that we’d need
              to build something blazing fast but at the same time manageable,
              so the client could edit, remove or add new content at will.
              That’s why we worked with our partners at{" "}
            </Trans>
            <a
              href="https://litebox.ai"
              className="strikethrough"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans> Litebox</Trans>
            </a>
            <Trans>and used</Trans>
            <a
              href="https://www.gatsbyjs.com/"
              className="strikethrough"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Gatsby JS</Trans>
            </a>
            <Trans>
              , a React Framework that allowed us to create a static website
              (fast) detached from a Content Management System (CMS).
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-wrapper">
        <Img
          className="image image--one"
          fluid={data.image1.childImageSharp.fluid}
          alt="LymeMIND"
        />
        <Img
          className="image image--two"
          fluid={data.image2.childImageSharp.fluid}
          alt="LymeMIND"
        />
      </div>
    </StyledSection>
  )
}

export default Solution