import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Trans } from "gatsby-plugin-react-i18next"

import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  overflow: hidden;

  .image-wrapper {
    width: 100%;
    margin-top: 56px;
    // overflow-x: hidden;

    ${breakpoint.medium`
      margin-top: 120px;
    `}

    .image {
      width: 756px !important;
      height: 100px !important;
      filter: drop-shadow(1px 28px 60px rgba(0, 43, 52, 0.08));

      ${breakpoint.medium`
        width: 1280px!important;
        height: 200px!important;
      `}

      &.image--one {
        left: 16px;
        margin-bottom: 16px;

        ${breakpoint.medium`
          left: 160px;
          margin-bottom: 32px;
        `}

        ${breakpoint.extraLarge`
          left: calc(100vw - 1280px);
          right: calc(100vw - 1280px);
        `}
      }

      &.image--two {
        object-position: center right !important;
        left: calc(100vw - 756px - 16px);

        ${breakpoint.medium`
          left: calc(100vw - 1280px - 160px);
        `}

        ${breakpoint.extraLarge`
          left: 0;
          right: calc(100vw - 1280px);
        `}
      }
    }
  }
`

const Project = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/lymemind/the-project-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/lymemind/the-project-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The project</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <p>
            <Trans>
              The LymeMIND team needed to rethink their website to enable novel
              scientific research and provide a consistent experience for their
              annual medical conference. They hoped to reframe Lyme disease as
              an important area, worthy of research and investment. We proposed
              a content-first approach that included brand messaging guidelines,
              as well as a complete visual rebranding. The design and
              development process was done simultaneously to ensure a dynamic
              site that took into consideration content updates.
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-wrapper">
        <div data-aos="indicius-slide-left">
          <Img
            className="image image--one"
            fluid={data.imageOne.childImageSharp.fluid}
            alt="LymeMIND"
          />
        </div>
        <div data-aos="indicius-slide-right">
          <Img
            className="image image--two"
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="LymeMIND"
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default Project