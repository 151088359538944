import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"
import Quote from "components/case-study-quote"

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  background-color: ${colors.cream};

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledCarousel = styled(Carousel)`
  width: 100%;
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  .slide {
    max-width: 928px;
  }
`

const StyledQuote = styled(Quote)`
  margin-top: 56px;

  ${breakpoint.medium`
    margin-top: 120px;
  `}
`

const Results = () => {
  const data = useStaticQuery(graphql`
    query {
      franciscoPhoto: file(
        relativePath: { eq: "case-studies/lymemind/kathleen-stecher.png" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      carouselImageOne: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carouselImageSix: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carouselImageSeven: file(
        relativePath: { eq: "case-studies/lymemind/the-results-image-7.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              A cohesive brand and a beautiful site that connected with
              LymeMIND's target audiences.
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              LymeMIND connects all of the different people taking part in the
              quest to find a cure for Lyme disease — and now their site
              reflects the massive impact they're having on the field of Lyme
              disease research. It allows them not only to show what's going on,
              but also to finally connect everyone to ultimately generate an
              even greater impact and keep searching for new treatment and
              possibly a cure.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              We helped the LymeMIND team get clear on their end goal from the
              start, so that we could use a content-first approach and create a
              site design that considered their different audiences and their
              need to easily manage their own content.
            </Trans>
          </p>
        </div>
      </div>
      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img fluid={data.carouselImageOne.childImageSharp.fluid} alt="Nubi" />
        </div>
        <div data-slide className="slide">
          <Img fluid={data.carouselImageTwo.childImageSharp.fluid} alt="Nubi" />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
        <div data-slide className="slide">
          <Img fluid={data.carouselImageSix.childImageSharp.fluid} alt="Nubi" />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageSeven.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
      </StyledCarousel>

      <div className="container">
        <StyledQuote
          quote={t(
            "“First of all, the website looks wonderful. It has a clean look with great detail. Everyone was impressed. ”"
          )}
          author="Kathleen Stecher"
          position={t("BS, Project Manager of the LymeMIND Conference 2020")}
          image={data.franciscoPhoto.childImageSharp.fixed}
          backgroundColor={colors.indiblue}
          color={colors.white}
        />
      </div>
    </StyledSection>
  )
}

export default Results