import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"

// Icons
import StickyOne from "assets/icons/case-studies/lymemind/sticky-1.inline.svg"
import StickyTwo from "assets/icons/case-studies/lymemind/sticky-2.inline.svg"
import StickyThree from "assets/icons/case-studies/lymemind/sticky-3.inline.svg"
import StickyFour from "assets/icons/case-studies/lymemind/sticky-4.inline.svg"
import { useTranslation } from "react-i18next"

const StyledSection = styled(Section)`
  padding-top: 56px !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}

  .stickies {
    width: 100%;
    display: flex;
    margin: 32px 0;
    overflow-x: auto;

    ${breakpoint.medium`
      margin: 80px 0;
      justify-content: space-between;
    `}

    svg {
      flex-shrink: 0;
      margin-right: 8px;

      ${breakpoint.medium`
        flex-shrink: 1;
      `}

      &:last-child {
        margin: 0;
      }
    }
  }
`

const Challange = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/lymemind/jason-bobe.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <StyledSection backgroundColor={colors.cream}>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              How can we create a website that appeals to multiple audiences
              while effectively managing content?
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              LymeMIND operates from within the Icahn School of Medicine in
              Mount Sinai. It's a multi-scale integrated network of Lyme disease
              that represents a unified predictive model of said disease.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              The design process to create the previous brand and the site
              wasn't optimal and they were having a hard time updating the site
              and clearly communicating the different stages of their annual
              conference.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              Internally, they had an entire team working on groundbreaking
              projects and tools related to Lyme disease, yet none of that was
              featured in their site. Additionally, they were part of a
              consortium of major players within Lyme disease research, which
              wasn't showcased on the site either.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              All of this led to outdated information and a disconnect between
              the different actors within the Lyme disease community, limiting
              the great impact they could have within the field.
            </Trans>
          </p>
        </div>

        <div className="stickies">
          <StickyOne />
          <StickyTwo />
          <StickyThree />
          <StickyFour />
        </div>

        <div className="section__title"></div>
        <div className="section__content">
          <Quote
            quote={t(
              "“People ought to be able to find truthful information about Lyme, Lyme research and treatment.”"
            )}
            author="Jason Bobe"
            position={t("MSc, Co-Principal Investigator")}
            image={data.file.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default Challange