import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Component
import FeaturedImage from "components/case-study-featured-image/"

// Images
import BlobGradients from "assets/images/case-studies/lymemind/blob-gradient.png"

export const transformBlob = keyframes`
0%,
100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
`

export const movementBlob = keyframes`
0%,
100% { transform: none; }
50% { transform: translateY(10%) rotateY(10deg); }
`

const BlobItem = styled.div`
  width: 320px;
  height: 320px;
  position: absolute;
  display: none;
  background-image: url(${BlobGradients});
  background-size: cover;
  box-shadow: 1px solid black;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  perspective: 1000px;
  animation: ${transformBlob} 10s ease-in-out infinite both alternate,
    ${movementBlob} 15s ease-in-out infinite both;

  ${breakpoint.small`
    display: block;
  `}

  &.blob--right {
    width: 210px;
    height: 210px;
    top: 140px;
    right: -64px;
  }

  &.blob--left {
    width: 120px;
    height: 120px;
    bottom: 80px;
    left: -24px;
  }
`

const StyledFeaturedImage = styled(FeaturedImage)`
  position: relative;
`

const Featuredimage = () => {
  const data = useStaticQuery(graphql`
    query {
      featuredImage: file(
        relativePath: { eq: "case-studies/lymemind/featured-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledFeaturedImage>
      <Img
        fluid={data.featuredImage.childImageSharp.fluid}
        resolutions={data.featuredImage.childImageSharp.resolutions}
        alt="LymeMIND"
      />

      <BlobItem className="blob--right" />

      <BlobItem className="blob--left" />
    </StyledFeaturedImage>
  )
}

export default Featuredimage